<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Home Edit </template>
      <template #left>
        <!-- <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/massage')"
        /> -->
      </template>
    </Toolbar>

    <div v-if="disabledAdd" class="text-center m-5">
      <Knob
        v-model="uploadProgress"
        valueColor="MediumTurquoise"
        rangeColor="SlateGray"
        :size="400"
        readonly
      />
    </div>
    <div v-else class="form-card row">
      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 1
            <span>*</span>
          </label>
          <img
            :src="body2.image1"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 2
            <span>*</span>
          </label>
          <img
            :src="body2.image2"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage2"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 3
            <span>*</span>
          </label>
          <img
            :src="body2.image3"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage3"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 4
            <span>*</span>
          </label>
          <img
            :src="body2.image4"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage4"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 5
            <span>*</span>
          </label>
          <img
            :src="body2.image5"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage5"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 6
            <span>*</span>
          </label>
          <img
            :src="body2.image6"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage6"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 7
            <span>*</span>
          </label>
          <img
            :src="body2.image7"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage7"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 8
            <span>*</span>
          </label>
          <img
            :src="body2.image8"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage8"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-5">
          <label for="Image" class="form-label required">
            Image 9
            <span>*</span>
          </label>
          <img
            :src="body2.image9"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="image/*"
              @change="previewImage9"
            ></b-form-file>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-5">
          <label for="homevideo" class="form-label required">
            Video
            <span>*</span>
          </label>
          <video
            :src="body2.homevideo"
            controls
            style="width: 400px; height: 400px"
          />
          <div class="position-relative">
            <i class="ri-pencil-line upload-button"></i>
            <b-form-file
              class="h-100 position-absolute"
              accept="video/*"
              @change="previewHomeVideo"
            ></b-form-file>
          </div>
        </div>
      </div>
    
      
      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="update()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        image9: null,
        homevideo: null,
        
      },
      body2: {
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        image8: null,
        image9: null,
        homevideo: null,
        
      },
      id: null,
      uploadProgress: 0,
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    getData() {
      this.$http.get(`home`).then(
        (res) => {
          this.body.image1 = this.$baseUploadURL + res.data.image1;
          this.body.image2 = this.$baseUploadURL + res.data.image2;
          this.body.image3 = this.$baseUploadURL + res.data.image3;
          this.body.image4 = this.$baseUploadURL + res.data.image4;
          this.body.image5 = this.$baseUploadURL + res.data.image5;
          this.body.image6 = this.$baseUploadURL + res.data.image6;
          this.body.image7 = this.$baseUploadURL + res.data.image7;
          this.body.image8 = this.$baseUploadURL + res.data.image8;
          this.body.image9 = this.$baseUploadURL + res.data.image9;
          this.body.homevideo = this.$baseUploadURL + res.data.homevideo;

          
          this.body2.image1 = this.$baseUploadURL + res.data.image1;
          this.body2.image2 = this.$baseUploadURL + res.data.image2;
          this.body2.image3 = this.$baseUploadURL + res.data.image3;
          this.body2.image4 = this.$baseUploadURL + res.data.image4;
          this.body2.image5 = this.$baseUploadURL + res.data.image5;
          this.body2.image6 = this.$baseUploadURL + res.data.image6;
          this.body2.image7 = this.$baseUploadURL + res.data.image7;
          this.body2.image8 = this.$baseUploadURL + res.data.image8;
          this.body2.image9 = this.$baseUploadURL + res.data.image9;
          this.body2.homevideo = this.$baseUploadURL + res.data.homevideo;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    previewImage(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image1 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image1 = image;
      });
    },
    previewImage2(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image2 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image2 = image;
      });
    },
    previewImage3(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image3 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image3 = image;
      });
    },
    previewImage4(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image4 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image4 = image;
      });
    },
    previewImage5(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image5 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image5 = image;
      });
    },
    previewImage6(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image6 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image6 = image;
      });
    },
    previewImage7(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image7 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image7 = image;
      });
    },
    previewImage8(ev) {
      this.$file2upload(ev, (image) => {
        this.body.image8 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image8 = image;
      });
    },
    previewImage9(ev) {
      2;
      this.$file2upload(ev, (image) => {
        this.body.image9 = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.image9 = image;
      });
    },
    previewHomeVideo(ev) {
      this.$file2upload(ev, (image) => {
        this.body.homevideo = image;
      });
      this.$file2base64(ev, (image) => {
        this.body2.homevideo = image;
      });
    },

    update() {
      this.disabledAdd = true;
      if (this.body.image1 && typeof this.body.image1 != "object") {
        delete this.body.image1;
      }
      if (this.body.image2 && typeof this.body.image2 != "object") {
        delete this.body.image2;
      }
      if (this.body.image3 && typeof this.body.image3 != "object") {
        delete this.body.image3;
      }
      if (this.body.image4 && typeof this.body.image4 != "object") {
        delete this.body.image4;
      }
      if (this.body.image5 && typeof this.body.image5 != "object") {
        delete this.body.image5;
      }
      if (this.body.image6 && typeof this.body.image6 != "object") {
        delete this.body.image6;
      }
      if (this.body.image7 && typeof this.body.image7 != "object") {
        delete this.body.image7;
      }

      if (this.body.image8 && typeof this.body.image8 != "object") {
        delete this.body.image8;
      }
      if (this.body.image9 && typeof this.body.image9 != "object") {
        delete this.body.image9;
      }

      if (this.body.homevideo && typeof this.body.homevideo != "object") {
        delete this.body.homevideo;
      }

      const formData = new FormData();

      if (typeof this.body.image1 == "object") {
        formData.append("image1", this.body.image1);
      }
      if (typeof this.body.image2 == "object") {
        formData.append("image2", this.body.image2);
      }
      if (typeof this.body.image3 == "object") {
        formData.append("image3", this.body.image3);
      }
      if (typeof this.body.image4 == "object") {
        formData.append("image4", this.body.image4);
      }
      if (typeof this.body.image5 == "object") {
        formData.append("image5", this.body.image5);
      }
      if (typeof this.body.image6 == "object") {
        formData.append("image6", this.body.image6);
      }
      if (typeof this.body.image7 == "object") {
        formData.append("image7", this.body.image7);
      }
      if (typeof this.body.image8 == "object") {
        formData.append("image8", this.body.image8);
      }
      if (typeof this.body.image9 == "object") {
        formData.append("image9", this.body.image9);
      }
      if (typeof this.body.homevideo == "object") {
        formData.append("homevideo", this.body.homevideo);
      }


      
      this.$http
        .post(`home`, formData, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );

            if (totalLength !== null) {
              this.uploadProgress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
            }
          },
        })
        .then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.getData();
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
  },
  created() {
    this.getData();
  },
};
</script>
